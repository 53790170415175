* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

.App {
  text-align: center;
  background-color: rgb(243, 243, 243, 0.521);
}

.section {
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9c9c9c;

}

.line-section {
  position: relative;
  left: 50%;
  transform: translateX(-50%) ;
  width: 80%;
  height: 0.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 700px) {
  html {
    font-size: 6px;
  }

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
